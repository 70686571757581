// ** React Imports
import { createContext, useState } from 'react'

const ValidationContext = createContext()

const ValidationProvider = ({ children }) => {
  const [countValidation, setCountValidation] = useState('a')
  const [canProceed, setCanProceed] = useState(false)
  const [errorsCredentials, setErrors] = useState([])

  const handleResetValidations = () => {
    setCanProceed(false)
    setCountValidation('a')
  }

  const changeProceed = (bool) => {
    setCanProceed(bool)
  }

  const changeErrors = (arr) => {
    if (arr.length > 0) {
      setErrors([...errorsCredentials, ...arr])
    }
  }

  const clearErrorsCredentials = () => {
    setErrors([])
  }

  const changeCountValidation = (letter) => {
    setCountValidation(letter)
  }

  const value = {
    countValidation,
    changeProceed,
    canProceed,
    changeErrors,
    errorsCredentials,
    clearErrorsCredentials,
    handleResetValidations,
    changeCountValidation
  }

  return (
    <ValidationContext.Provider value={value}>
      {children}
    </ValidationContext.Provider>
  )
}

export { ValidationContext, ValidationProvider }
