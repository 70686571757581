// ** React Imports
import { useState, createContext } from 'react'

// ** Componentes
import FallbackSpinnerComponent from '@components/spinner/Fallback-spinner'
import ComponentSpinner from '@components/spinner/Background-spinner'

// ** Create Context
const GlobalLoaderContext = createContext()

const GlobalLoaderProvider = ({ children }) => {
  // ** State
  const [global_loader, setGlobalLoader] = useState(false)
  const [background_loader, setBackgroundLoader] = useState(false)

  return (
    <GlobalLoaderContext.Provider value={{ global_loader, setGlobalLoader, background_loader, setBackgroundLoader }}>
        {global_loader && <FallbackSpinnerComponent fixed />}
        {background_loader && <ComponentSpinner fixed />}
        <div className={`${global_loader || background_loader ? 'loading-body' : ''}`}>
          {children}
        </div>
    </GlobalLoaderContext.Provider>
  ) 
}

export { GlobalLoaderContext, GlobalLoaderProvider }
