import React from 'react'
import { GlobalLoaderContext } from './context/GlobalLoader'

let globalLoaderContextValue = null

export const GlobalLoaderProviderWrapper = ({ children }) => {
  const contextValue = React.useContext(GlobalLoaderContext)
  globalLoaderContextValue = contextValue
  return children
}

export const showGlobalLoader = () => {
  if (globalLoaderContextValue) {
    globalLoaderContextValue.setGlobalLoader(true)
  }
}

export const hideGlobalLoader = () => {
  if (globalLoaderContextValue) {
    globalLoaderContextValue.setGlobalLoader(false)
  }
}