import { postReq, getReq } from "@services/api"

export const session = {
  login: async (body) => { return await postReq('login', '/auth/acessar', body, false) },
  validaEmail: async (email) => { return await getReq('valida e-mail', `/auth/valida-email/${email}`, false) },
  sendToken: async (body) => { return await postReq('enviar token da twilio', '/auth/envia-token-twilio', body, false) },
  verifyToken: async (body) => { return await postReq('verificar token da twilio', '/auth/verifica-token-twilio', body, false) },
  refreshToken: async () => { return await postReq('reenviar token da twilio', '/auth/refresh-token', '', false) },
  register: async (body) => { return await postReq('cadastrar', '/auth/registrar', body, false) },
  recovery_password: async (body) => { return await postReq('recuperar senha', `/auth/esqueci-minha-senha`, body, false) },
  new_password: async (body) => { return await postReq('nova senha', '/auth/alterar-senha', body, false) },
  oauth2: async (body) => { return await postReq('oauth2', `/auth/oauth2`, body, true) },
  oauth2_sso: async (chave) => { return await getReq('oauth2', `/auth/sso-cliente/${chave}`, false) },
  forgot_sso: async (email) => { return await getReq('oauth2', `/auth/forgot-sso-cliente/${email}`, false) }
}
