import { useState, createContext } from 'react'

const SSOContext = createContext()

const SSOProvider = ({ children }) => {
  const [ssoActive, setSsoActive] = useState(false)

  const handleResetSSO = () => {
    setSsoActive(false)
  }

  const value = {
    handleResetSSO,
    ssoActive,
    setSsoActive
  }

  return (
    <SSOContext.Provider value={value}>
      {children}
    </SSOContext.Provider>
  )
}

export { SSOContext, SSOProvider }
