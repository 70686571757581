import { MsalProvider } from '@azure/msal-react'
import { useState, createContext } from 'react'

const OauthContext = createContext()

const OauthProvider = ({ children }) => {
  const [instance, setInstance] = useState(null)
  const [initiated, setInitiated] = useState(false)
  const [propagating, setPropagating] = useState(true)

  const handleResetOauth = () => {
    setInstance(null)
    setInitiated(false)
  }

  const handleStopPropagation = () => {
    setPropagating(false)
  }

  const handleStartPropagation = () => {
    setPropagating(true)
  }

  const value = {
    handleResetOauth,
    handleStopPropagation,
    handleStartPropagation,
    propagating,
    setInstance,
    initiated,
    setInitiated
  }

  return (
    <OauthContext.Provider value={value}>
      {instance ? (
        <MsalProvider instance={instance}>
          {children}
        </MsalProvider>
      ) : (
        children
      )}
    </OauthContext.Provider>
  )
}

export { OauthContext, OauthProvider }
